var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"color":"primary","clipped-left":"","dark":"","app":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.toggleMenu.apply(null, arguments)}}}),_c('v-toolbar-title',{staticClass:"ml-0 pl-4 d-flex align-center"},[_c('span',[_vm._v("OperaGest")]),(_vm.showUkraineFlag)?_c('v-icon',{staticClass:"ml-5",attrs:{"large":""}},[_vm._v("$ukrainian")]):_vm._e()],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"bottom"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"icon"},on:{"click":_vm.changeTheme}},on),[_c('v-icon',[_vm._v("mdi-theme-light-dark")])],1)]}}])},[_c('span',[_vm._v("Cambia tema")])]),_c('v-menu',{ref:"colourMenu",attrs:{"close-on-content-click":false,"left":"","open-on-hover":"","nudge-bottom":28,"transition":"scale-transition","rounded":"xl"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"icon"},on:{"click":_vm.changePalette}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-color-fill")])],1)]}}]),model:{value:(_vm.colourMenu),callback:function ($$v) {_vm.colourMenu=$$v},expression:"colourMenu"}},[_c('v-color-picker',{attrs:{"elevation":"15"},model:{value:(_vm.primaryColour),callback:function ($$v) {_vm.primaryColour=$$v},expression:"primaryColour"}})],1),(_vm.showInfoButton)?_c('v-tooltip',{attrs:{"bottom":"bottom"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"icon"},on:{"click":_vm.showInfo}},on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,false,2197003845)},[_c('span',[_vm._v("Informazioni")])]):_vm._e(),_c('v-menu',{ref:"userMenu",attrs:{"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"icon"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}]),model:{value:(_vm.userMenu),callback:function ($$v) {_vm.userMenu=$$v},expression:"userMenu"}},[_c('v-list',[_c('v-list-item-group',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.$store.getters.roleIcon))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$store.getters.role))])]),_c('span',[_vm._v(_vm._s(_vm.$store.state.user.nomeUtente))])],1),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$store.state.user.email))])],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"to":"./user-info"}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-information-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Profilo")])],1)],1),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }