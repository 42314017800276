
























































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { OspitiReturned, TipoDocumentoIdentita } from "operatn-api-client";

import TipoDocumentoIdentitaHandlerMixin from '@/mixins/handlers/TipoDocumentoIdentitaHandlerMixin';
import CopyMixin from "@/mixins/CopyMixin";

import OperatnPdfViewer from '@/components/gears/misc/OperatnPdfViewer.vue';

@Component({
  components: {
    OperatnPdfViewer
  }
})
export default class OperatnDocumentoInfo extends Mixins(TipoDocumentoIdentitaHandlerMixin, CopyMixin) {
  /* PROPS */

  @Prop({ type: Object, required: true })
  ospite!: OspitiReturned;

  /* DATA */

  private tipiDocumentoIdentita: TipoDocumentoIdentita[] = [];

  /* GETTERS */

  get documentoPath(): string | null {
    return this.ospite?.documentoIdentita?.documento ? this.$stored.getPath(this.ospite.documentoIdentita.documento) : null;
  }
  get dataRilascio(): string | null {
    return this.ospite?.documentoIdentita?.dataRilascio ? this.ospite.documentoIdentita.dataRilascio.toLocaleDateString() : null;
  }
  get dataScadenza(): string | null {
    return this.ospite?.documentoIdentita?.dataScadenza ? this.ospite.documentoIdentita.dataScadenza.toLocaleDateString() : null;
  }
  get tipoDocumento(): string {
    const currValue = this.ospite?.documentoIdentita?.idTipoDocumentoIdentita;
    return currValue ? this.tipiDocumentoIdentita.find(el => el.id === currValue)?.tipoDocumentoIdentita ?? 'SCONOSCIUTO' : 'SCONOSCIUTO';
  }

  /* METHODS */

  async downloadDocumento(): Promise<void> {
    if (this.ospite?.documentoIdentita?.documento) {
      await this.$stored.download(this.ospite.documentoIdentita.documento, `documento_identita_${this.ospite.id}.pdf`);
    }
  }

  /* LIFE CYCLE */

  async mounted() {
    this.tipiDocumentoIdentita = await this.getTipiDocumentoIdentita();
  }
}
