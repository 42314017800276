






































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ContrattiCheckoutBody } from "operatn-api-client";

import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";

export interface ContrattoCheckoutFormInfo {
  personaNome: string;
  personaCognome: string;
  fabbricato: string;
  stanza: string;
}

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {
    OperatnDateInput,
  },
})
export default class OperatnContrattoCheckOutForm extends Vue {
  /* PROPS */

  @Prop({ validator: (v) => v instanceof Object || v === null, default: null })
  value!: ContrattiCheckoutBody | null;

  @Prop({ type: Object, required: true })
  info!: ContrattoCheckoutFormInfo;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  /* DATA */

  private lasciapassareValued = false;
  private kitValued = false;

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  get wrappedLasciapassareValued() {
    return this.lasciapassareValued;
  }
  set wrappedLasciapassareValued(value) {
    this.lasciapassareValued = value;
    if (value && this.internalValue && !this.internalValue.dataConsegnaLasciapassare) {
      this.internalValue.dataConsegnaLasciapassare = new Date();
    } else if (!value && this.internalValue?.dataConsegnaLasciapassare) {
      this.internalValue.dataConsegnaLasciapassare = null;
    }
  }

  get wrappedKitValued() {
    return this.kitValued;
  }
  set wrappedKitValued(value) {
    this.kitValued = value;
    if (value && this.internalValue && !this.internalValue.dataConsegnaKit) {
      this.internalValue.dataConsegnaKit = new Date();
    } else if (!value && this.internalValue?.dataConsegnaKit) {
      this.internalValue.dataConsegnaKit = null;
    }
  }

  /* METHODS */

  getEmptyValue(): ContrattiCheckoutBody {
    return {
      data: new Date(),
      dataConsegnaLasciapassare: null,
      dataConsegnaKit: null,
      note: null,
    };
  }

  /* LIFE CYCLE */

  created() {
    if (this.value === null) {
      this.internalValue = this.getEmptyValue();
    }
  }

  /* WATCH */

  @Watch("value", { deep: true, immediate: true })
  watchValue() {
    if (this.value === null) {
      this.internalValue = this.getEmptyValue();
    }
  }

  @Watch("internalValue.dataConsegnaLasciapassare")
  watchInternalValueConsegnaLasciapassare() {
    if (this.internalValue?.dataConsegnaLasciapassare) {
      this.wrappedLasciapassareValued = true;
    } else {
      this.wrappedLasciapassareValued = false;
    }
  }

  @Watch("internalValue.dataConsegnaKit")
  watchInternalValueConsegnaKit() {
    if (this.internalValue?.dataConsegnaKit) {
      this.wrappedKitValued = true;
    } else {
      this.wrappedKitValued = false;
    }
  }
}
