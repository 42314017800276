




























































































import { Component, Vue } from "vue-property-decorator";
import { RuoloUtente } from "operatn-api-client";

import { ActionTypes } from "@/store";

@Component
export default class Bar extends Vue {
  /* DATA */

  private colourMenu = false;
  private userMenu = false;

  /* COMPUTED */

  get primaryColour(): any {
    return this.$store.state.primaryColour ? this.$store.state.primaryColour : "#1976D2";
  }
  set primaryColour(colour: any) {
    if (colour === null || typeof colour === "string") {
      this.$store.dispatch(ActionTypes.CHANGE_PRIMARY_COLOUR, colour);
    } else {
      this.$store.dispatch(ActionTypes.CHANGE_PRIMARY_COLOUR, colour.hex);
    }
  }

  get showInfoButton(): boolean {
    return !!this.$store.state.infoDialogText;
  }

  get role(): RuoloUtente {
    return this.$store.getters.role;
  }

  get showUkraineFlag(): boolean {
    return [RuoloUtente.ROOT, RuoloUtente.ADMIN, RuoloUtente.RAGIONERIA, RuoloUtente.CONTROLLO_ALLOGGI].includes(this.role);
  }

  /* METHODS */

  toggleMenu(): void {
    this.$store.dispatch(ActionTypes.TOGGLE_MENU);
  }

  changeTheme(): void {
    this.$store.dispatch(ActionTypes.TOGGLE_DARK_THEME);
  }

  showInfo(): void {
    this.$store.dispatch(ActionTypes.SHOW_INFO);
  }

  getRandomColor(): string {
    const letters = "0123456789ABCDEF";
    return "#" + [...Array(6)].map(() => letters[Math.floor(Math.random() * 16)]).join("");
  }

  changePalette(): void {
    const colour = this.getRandomColor();
    this.$store.dispatch(ActionTypes.CHANGE_PRIMARY_COLOUR, colour);
  }

  logout(): void {
    this.$store.dispatch(ActionTypes.LOGOUT);
  }
}
