import { Component, Vue } from "vue-property-decorator";
import { OspitiReturned } from "operatn-api-client";

import { ActionTypes } from "@/store";

@Component
export default class ApiUnitnHandlerMixin extends Vue {

  async searchOspitiWithApi(nome: string, cognome: string): Promise<Partial<OspitiReturned>[]> {
    try {
      return this.$api.apiUnitn.getOspiti({ nome, cognome });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile caricare ospiti da api unitn` });
      }
      throw error;
    }
  }

}