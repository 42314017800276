




import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { OspitiReturned, PresidentiReturned } from "operatn-api-client";

import OperatnPostoInfo from "./OperatnPostoInfo.vue";

@Component({
  components: {
    OperatnPostoInfo,
  },
})
export default class OperatnLuogoNascitaInfo extends Vue {
  /* PROPS */

  @Prop({ type: Object, required: true })
  persona!: OspitiReturned | PresidentiReturned;
}
