















































































































import { Component, Prop, Vue } from "vue-property-decorator";

import OperatnActionDialog from "@/components/gears/dialogs/OperatnActionDialog.vue";
import OperatnBaseTable, { Actions, Column, GroupHeaders, RowColors } from "@/components/gears/bases/OperatnBaseTable.vue";
import { DataOptions } from "vuetify";

export { Actions, Column, GroupHeaders } from "@/components/gears/bases/OperatnBaseTable.vue";

@Component({
  components: {
    OperatnActionDialog,
    OperatnBaseTable,
  },
})
export default class OperatnBaseResourceManager extends Vue {
  /* PROPS */

  @Prop({ type: Boolean, default: false })
  hasBackButton!: boolean;

  @Prop({ type: Boolean, default: true })
  isCard!: boolean;

  @Prop({ type: String, required: true })
  title!: string;

  @Prop({ type: String, required: false })
  description?: string;

  @Prop({ type: Boolean, default: true })
  showTable!: boolean;

  @Prop({ type: String, required: true })
  tableTitle!: string;

  @Prop({ type: Array, required: true })
  tableColumns!: Column[];

  @Prop({ type: Object, required: true })
  tableActions!: Actions;

  @Prop({ type: Object, required: false })
  tableGroupHeaders?: GroupHeaders;

  @Prop({ type: Array, required: true })
  tableSelectedValues!: any[];

  @Prop({ type: Array, required: true })
  tableValues!: any[];

  @Prop({ type: String, required: true })
  tableItemKey!: string;

  @Prop({ type: Boolean, default: false })
  tableShowSelect!: boolean;

  @Prop({ type: String, required: false })
  tableSortBy?: string;

  @Prop({ type: Boolean, required: false })
  tableSortDesc?: boolean;

  @Prop({ type: Boolean, default: false })
  tableMultiSort!: boolean;

  @Prop({ type: Boolean, default: false })
  tableLoading!: boolean;

  @Prop({ type: Boolean, default: true })
  tableShowTitle!: boolean;

  @Prop({ validator: (v) => typeof v === "object" || v === null, required: true })
  tableUpdateBody!: any;

  @Prop({ type: Number, required: false })
  tableServerItemsLength?: number;

  @Prop({ type: Object, required: false })
  tableOptions?: DataOptions;

  @Prop({ type: Array, required: false })
  tableItemsPerPageOptions?: number[];

  @Prop({ type: String, required: false })
  tableSearch?: string;

  @Prop({ type: String, required: true })
  createDialogTitle!: string;

  @Prop({ type: Boolean, default: false })
  createDialogShow!: boolean;

  @Prop({ type: Boolean, required: true })
  createDialogDisabled!: boolean;

  @Prop({ type: String, required: true })
  editDialogTitle!: string;

  @Prop({ type: Boolean, default: false })
  editDialogShow!: boolean;

  @Prop({ type: Boolean, required: true })
  editDialogDisabled!: boolean;

  @Prop({ type: Boolean, default: true })
  showActionButton!: boolean;

  @Prop({ type: String, required: false })
  dialogWidth?: string;

  @Prop({ type: String, required: false })
  dialogWidthMobile?: string;

  @Prop({ type: Function, default: (item) => "" })
  rowBackgrounds!: (item: any) => RowColors;

  @Prop({ type: Object, required: false })
  exportLoadings?: { tsv: boolean; xlsx: boolean };

  @Prop({ type: Boolean, default: false })
  exportTsv!: boolean;

  @Prop({ type: Boolean, default: false })
  exportXlsx!: boolean;

  /* GETTERS AND SETTERS */

  get internalTableSelectedValues(): any[] {
    return this.tableSelectedValues;
  }
  set internalTableSelectedValues(value: any[]) {
    this.$emit("update:table-selected-values", value);
  }

  get internalTableOptions(): DataOptions | undefined {
    return this.tableOptions;
  }
  set internalTableOptions(value: DataOptions | undefined) {
    this.$emit("update:table-options", value);
  }
  get internalTableSearch(): string | undefined {
    return this.tableSearch;
  }
  set internalTableSearch(value: string | undefined) {
    this.$emit("update:table-search", value);
  }

  get internalTableUpdateBody(): any {
    return this.tableUpdateBody;
  }
  set internalTableUpdateBody(value: any) {
    this.$emit("update:table-update-body", value);
  }

  get internalCreateDialogShow(): boolean {
    return this.createDialogShow;
  }
  set internalCreateDialogShow(value: boolean) {
    this.$emit("update:create-dialog-show", value);
  }

  get internalEditDialogShow(): boolean {
    return this.editDialogShow;
  }
  set internalEditDialogShow(value: boolean) {
    this.$emit("update:edit-dialog-show", value);
  }

  get isSelecting(): boolean {
    return this.internalTableSelectedValues.length > 0;
  }

  /* METHODS */

  fabCreateClick(): void {
    this.$emit("fabCreateClick");
  }
  fabDeleteClick(): void {
    this.$emit("fabDeleteClick");
  }

  createDialogCancel(): void {
    this.$emit("createDialogCancel");
  }
  createDialogConfirm(): void {
    this.$emit("createDialogConfirm");
  }

  editDialogCancel(): void {
    this.$emit("editDialogCancel");
  }
  editDialogConfirm(): void {
    this.$emit("editDialogConfirm");
  }

  tableRowClicked(item: any): void {
    this.$emit("tableRowClicked", item);
  }

  goBack() {
    this.$router.go(-1);
  }
}
