













































































































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { OspitiCreateBody, OspitiReturned, OspitiUpdateBody } from "operatn-api-client";

import OperatnSearchOspiteWithApi from "./gears/OperatnSearchOspiteWithApi.vue";
import OperatnDatiPersona from "./gears/OperatnDatiPersona.vue";
import OperatnDatiOspite from "./gears/OperatnDatiOspite.vue";
import OperatnLuogoNascita from "./gears/OperatnLuogoNascita.vue";
import OperatnResidenza from "./gears/OperatnResidenza.vue";
import OperatnDomicili from "./gears/OperatnDomicili.vue";
import OperatnDipartimentoUnitn from "./gears/OperatnDipartimentoUnitn.vue";
import OperatnContoCorrente from "./gears/OperatnContoCorrente.vue";
import OperatnDocumentoIdentita from "./gears/OperatnDocumentoIdentita.vue";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {
    OperatnSearchOspiteWithApi,
    OperatnDatiPersona,
    OperatnDatiOspite,
    OperatnLuogoNascita,
    OperatnResidenza,
    OperatnDomicili,
    OperatnDipartimentoUnitn,
    OperatnContoCorrente,
    OperatnDocumentoIdentita,
  },
})
export default class OperatndOspiteForm extends Vue {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<OspitiCreateBody> | Partial<OspitiUpdateBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  @Prop({ validator: (v) => v instanceof File || typeof v === 'string' || v === null, default: null })
  foto!: File | string | null;

  @Prop({ validator: (v) => v instanceof File || typeof v === 'string' || v === null, default: null })
  documento!: File | string | null;

  /* DATA */

  private detailsExpanded = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  private datiPersonaValid = false;
  private datidOspiteValid = false;
  private luogoNascitaValid = false;
  private residenzaValid = false;
  private domiciliValid = false;
  private dipartimentoUnitnValid = false;
  private contoCorrenteValid = false;
  private documentoIdentitaValid = false;

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  get internalFoto() {
    return this.foto;
  }
  set internalFoto(value) {
    this.$emit("update:foto", value);
  }

  get internalDocumento() {
    return this.documento;
  }
  set internalDocumento(value) {
    this.$emit("update:documento", value);
  }

  /* METHODS */

  getEmptyValue(): Partial<OspitiCreateBody> | Partial<OspitiUpdateBody> {
    return {
      nome: undefined,
      cognome: undefined,
      sesso: undefined,
      poliziotto: false,
      codiceFiscale: null,
      dataDiNascita: undefined,
      idGiada: null,
      email: undefined,
      telefonoPrincipale: null,
      telefonoSecondario: null,
      cittadinanza: undefined,
      codiceDipartimentoUnitn: null,
      contoCorrente: null,
      documentoIdentita: null,
      luogoDiNascita: undefined,
      residenza: undefined,
      domicili: [],
    };
  }

  computeFormValid(): boolean {
    return (
      this.datiPersonaValid &&
      this.datidOspiteValid &&
      this.luogoNascitaValid &&
      this.residenzaValid &&
      this.domiciliValid &&
      this.dipartimentoUnitnValid &&
      this.contoCorrenteValid &&
      this.documentoIdentitaValid
    );
  }

  selectOspite(ospite: OspitiReturned): void {
    this.internalValue = ospite;
  }

  /* WATCH */

  @Watch("value", { deep: true, immediate: true })
  watchValue() {
    if (this.internalValue === null) {
      this.internalValue = this.getEmptyValue();
    }
  }

  @Watch("datiPersonaValid")
  watchDatiPersonaValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }
  @Watch("datidOspiteValid")
  watchDatidOspiteValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }
  @Watch("luogoNascitaValid")
  watchLuogoNascitaValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }
  @Watch("residenzaValid")
  watchResidenzaValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }
  @Watch("domiciliValid")
  watchDomiciliValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }
  @Watch("dipartimentoUnitnValid")
  watchDipartimentoUnitnValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }
  @Watch("contoCorrenteValid")
  watchContoCorrenteValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }
  @Watch("documentoIdentitaValid")
  watchDocumentoIdentitaValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }

  /* LIFE CYCLE */

  async mounted() {
    if (this.internalValue === null) {
      this.internalValue = this.getEmptyValue();
    }
  }
}
