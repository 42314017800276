
















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import OperatnPdfViewer from '@/components/gears/misc/OperatnPdfViewer.vue'

@Component({
  model: {
    prop: "value",
    event: "change",
  },
  components: {
    OperatnPdfViewer
  }
})
export default class OperatnImageInput extends Vue {
  /* PROPS */

  @Prop({ validator: (v) => typeof v === "string" || v instanceof File || v === null, required: true })
  value!: File | string | null;

  @Prop({ type: String, default: "IMMAGINE" })
  label!: string;

  /* DATA */

  private dialogShown = false;
  private selectedFile: File | null = null;

  /* GETTERS AND SETTERS */

  get internalValue(): File | string | null {
    return this.value;
  }
  set internalValue(value: File | string | null) {
    this.$emit("change", value);
  }

  get pdfPreview(): string | null {
    if (this.internalValue === null) {
      return null;
    } else if (typeof this.internalValue === "string") {
      return this.internalValue;
    } else {
      return URL.createObjectURL(this.internalValue);
    }
  }

  /* METHODS */

  async showDialog(): Promise<void> {
    if (this.internalValue === null) {
      this.selectedFile = null;
    } else if (typeof this.internalValue === "string") {
      const response = await fetch(this.internalValue);
      const data = await response.blob();
      const metadata = {
        type: "image/png",
      };
      this.selectedFile = new File([data], `${this.label}.png`, metadata);
    } else {
      this.selectedFile = this.internalValue;
    }
    this.dialogShown = true;
  }
  closeDialog(): void {
    this.selectedFile = null;
    this.dialogShown = false;
  }
  saveDialog(): void {
    this.internalValue = this.selectedFile;
    this.closeDialog();
  }
}
