















































import { Component, Mixins, Prop } from "vue-property-decorator";
import { OspitiCreateBody, OspitiUpdateBody, DipartimentoUnitn } from "operatn-api-client";

import DipartimentoUnitnHandlerMixin from "@/mixins/handlers/DipartimentoUnitnHandlerMixin";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
})
export default class OperatnDipartimentoUnitn extends Mixins(DipartimentoUnitnHandlerMixin) {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<OspitiCreateBody> | Partial<OspitiUpdateBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  /* DATA */

  private dipartimentiUnitn: DipartimentoUnitn[] = [];

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  get selectedDipartimentoUnitn(): null | DipartimentoUnitn {
    return this.dipartimentiUnitn.find((q) => q.codice === this.internalValue.codiceDipartimentoUnitn) ?? null;
  }

  /* LIFE CYCLE */

  async mounted() {
    this.dipartimentiUnitn = await this.getDipartimentiUnitn();
  }
}
