










































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { OspitiReturned } from "operatn-api-client";

import CopyMixin from "@/mixins/CopyMixin";

@Component
export default class OperatnOspiteInfo extends Mixins(CopyMixin) {
  /* PROPS */

  @Prop({ type: Object, required: true })
  ospite!: OspitiReturned;

  /* GETTERS */

  get fotoPath(): string | null {
    return this.ospite.foto ? this.$stored.getPath(this.ospite.foto) : null;
  }

  /* METHODS */

  async downloadFoto(): Promise<void> {
    if (this.ospite.foto) {
      await this.$stored.download(this.ospite.foto, 'foto.png');
    }
  }
}
