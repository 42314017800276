


























































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { OspitiReturned, RuoloUtente } from "operatn-api-client";

import OspiteHandlerMixin from "@/mixins/handlers/OspiteHandlerMixin";

import OperatnPersonaInfo from "@/components/gears/infos/persona/OperatnPersonaInfo.vue";
import OperatnOspiteInfo from "@/components/gears/infos/persona/OperatnOspiteInfo.vue";
import OperatnLuogoNascitaInfo from "@/components/gears/infos/persona/OperatnLuogoNascitaInfo.vue";
import OperatnResidenzaInfo from "@/components/gears/infos/persona/OperatnResidenzaInfo.vue";
import OperatnDomiciliInfo from "@/components/gears/infos/persona/OperatnDomiciliInfo.vue";
import OperatnContoCorrenteInfo from "@/components/gears/infos/persona/OperatnContoCorrenteInfo.vue";
import OperatnDocumentoInfo from "@/components/gears/infos/persona/OperatnDocumentoInfo.vue";
import OperatnOspiteCauzioneInfo from "@/components/gears/infos/persona/OperatnOspiteCauzioneInfo.vue";
import OperatnOspiteContrattiInfo from "@/components/gears/infos/persona/OperatnOspiteContrattiInfo.vue";

@Component({
  components: {
    OperatnPersonaInfo,
    OperatnOspiteInfo,
    OperatnLuogoNascitaInfo,
    OperatnResidenzaInfo,
    OperatnDomiciliInfo,
    OperatnContoCorrenteInfo,
    OperatnDocumentoInfo,
    OperatnOspiteCauzioneInfo,
    OperatnOspiteContrattiInfo
  },
})
export default class OspitiId extends Mixins(OspiteHandlerMixin) {
  /* PROPS */

  @Prop({ type: Number, required: true })
  id!: number;

  /* DATA */

  private ospite: OspitiReturned | null = null;
  private detailsExpanded = 0;

  /* GETTERS */

  get role(): RuoloUtente {
    return this.$store.getters.role;
  }

  get canSeeAllDetails(): boolean {
    return [RuoloUtente.ROOT, RuoloUtente.ADMIN, RuoloUtente.SPORTELLO, RuoloUtente.RAGIONERIA].includes(this.role);
  }

  /* METHODS */

  async downloadFoto(item: OspitiReturned): Promise<void> {
    await this.$stored.download(item.foto as string, `foto_ospite_${item.id}`);
  }

  /* LIFE CYCLE */

  async created() {
    this.ospite = await this.getOspite(this.id, {
      persona: {
        luogoDiNascita: true,
        residenza: true,
        domicili: true,
      },
      contoCorrente: true,
      documentoIdentita: true
    });

    this.detailsExpanded = this.canSeeAllDetails ? 0 : 6;
  }
}
