
















































import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import { OspitiCreateBody, OspitiUpdateBody, TipoDocumentoIdentita } from "operatn-api-client";

import TipoDocumentoIdentitaHandlerMixin from "@/mixins/handlers/TipoDocumentoIdentitaHandlerMixin";

import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";
import OperatnPdfInput from "@/components/gears/inputs/OperatnPdfInput.vue";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {
    OperatnDateInput,
    OperatnPdfInput,
  },
})
export default class OperatnDocumentoIdentita extends Mixins(TipoDocumentoIdentitaHandlerMixin) {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<OspitiCreateBody> | Partial<OspitiUpdateBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  @Prop({ validator: (v) => v instanceof File || typeof v === "string" || v === null, default: null })
  documento!: File | string | null;

  /* DATA */

  private idTipoDocumentoIdentita: string | number | null = null;
  private numero = "";
  private ente = "";
  private dataScadenza: Date | null = null;
  private dataRilascio: Date | null = null;

  private localIsFormValid = false;

  private tipiDocumentoIdentita: TipoDocumentoIdentita[] = [];

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  get internalDocumento() {
    return this.documento;
  }
  set internalDocumento(value) {
    this.$emit("update:documento", value);
  }

  get tipoDocumentoHint(): string | null {
    return typeof this.idTipoDocumentoIdentita === "string" ? `Il valore delle api ${this.idTipoDocumentoIdentita} non è valido` : null;
  }

  /* METHODS */

  initialAssignation(): void {
    if (this.internalValue.documentoIdentita?.idTipoDocumentoIdentita) {
      this.idTipoDocumentoIdentita = this.internalValue.documentoIdentita.idTipoDocumentoIdentita;
    }

    if (this.internalValue.documentoIdentita?.numero) {
      this.numero = this.internalValue.documentoIdentita.numero;
    }

    if (this.internalValue.documentoIdentita?.ente) {
      this.ente = this.internalValue.documentoIdentita.ente;
    }

    if (this.internalValue.documentoIdentita?.dataScadenza) {
      this.dataScadenza = this.internalValue.documentoIdentita.dataScadenza;
    }

    if (this.internalValue.documentoIdentita?.dataRilascio) {
      this.dataRilascio = this.internalValue.documentoIdentita.dataRilascio;
    }

    this.inputChanged();
  }

  inputChanged(): void {
    if ([this.idTipoDocumentoIdentita, this.numero, this.ente, this.dataScadenza, this.dataRilascio, this.internalDocumento].every((v) => !v)) {
      this.internalValue.documentoIdentita = null;
      this.internalFormValid = true;
    } else {
      this.internalValue.documentoIdentita = {
        idTipoDocumentoIdentita: this.idTipoDocumentoIdentita as number,
        numero: this.numero,
        ente: this.ente,
        dataRilascio: this.dataRilascio as Date,
        dataScadenza: this.dataScadenza ?? null,
      };
      this.internalFormValid = this.localIsFormValid;
    }
  }

  /* WATCHERS */

  @Watch("idTipoDocumentoIdentita")
  watchIban() {
    this.inputChanged();
  }
  @Watch("numero")
  watchBanca() {
    this.inputChanged();
  }
  @Watch("ente")
  watchEnte() {
    this.inputChanged();
  }
  @Watch("dataScadenza")
  watchScadenza() {
    this.inputChanged();
  }
  @Watch("dataRilascio")
  watchRilascio() {
    this.inputChanged();
  }
  @Watch("internalDocumento")
  watchInternalDocumento() {
    this.inputChanged();
  }

  @Watch("internalValue")
  watchInternalValue() {
    this.initialAssignation();
  }

  @Watch("localIsFormValid")
  watchLocalIsFormValid() {
    if (this.internalValue.documentoIdentita) {
      this.internalFormValid = this.localIsFormValid && !!this.internalDocumento;
    }
  }

  /* LIFE CYCLE */

  async created() {
    this.tipiDocumentoIdentita = await this.getTipiDocumentoIdentita();
    this.initialAssignation();
  }
}
