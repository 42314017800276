









































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import {
  OspitiReturned,
  OspitiCreateBody,
  OspitiUpdateBody,
  LuogoDiNascita,
  Residenza,
  OspitiIncludeParams,
  OspitiPageParams,
  OspitiOrderByParams,
  OspitiSearchParams,
  RuoloUtente,
} from "operatn-api-client";
import { DataOptions } from "vuetify";

import { AlertType } from "@/store";

import ResourceManagerMixin from "@/mixins/ResourceManagerMixin";
import OspiteHandlerMixin from "@/mixins/handlers/OspiteHandlerMixin";

import OperatnActionDialog from "@/components/gears/dialogs/OperatnActionDialog.vue";
import OperatnBaseResourceManager, { Column, Actions } from "@/components/gears/bases/OperatnBaseResourceManager.vue";
import OperatnOspiteForm from "@/components/gears/forms/persona/OperatnOspiteForm.vue";

@Component({
  components: {
    OperatnActionDialog,
    OperatnBaseResourceManager,
    OperatnOspiteForm,
  },
})
export default class Ospiti extends Mixins<ResourceManagerMixin<OspitiReturned, OspitiCreateBody, OspitiUpdateBody, number> & OspiteHandlerMixin>(
  ResourceManagerMixin,
  OspiteHandlerMixin
) {
  /* PROPS */

  @Prop({ type: Boolean, default: false })
  isRoot!: boolean;

  @Prop({ type: Boolean, default: false })
  createDialogOpen!: boolean;

  /* DATA */

  protected askDeleteText = "Sei sicuro di voler eliminare questo ospite?";
  protected askDeleteMultipleText = "Sei sicuro di voler eliminare i ospiti selezionati?";

  private loading = false;
  private foto: File | string | null = null;
  private documento: File | string | null = null;

  /* GETTERS AND SETTERS */

  get canEdit(): boolean {
    return [RuoloUtente.ROOT, RuoloUtente.ADMIN, RuoloUtente.SPORTELLO].includes(this.$store.getters.role);
  }

  get canCreate(): boolean {
    return [RuoloUtente.ROOT, RuoloUtente.ADMIN, RuoloUtente.SPORTELLO].includes(this.$store.getters.role);
  }

  get canSeeAllDetails(): boolean {
    return [RuoloUtente.ROOT, RuoloUtente.ADMIN, RuoloUtente.SPORTELLO, RuoloUtente.RAGIONERIA].includes(this.$store.getters.role);
  }

  get columns(): Column<OspitiReturned>[] {
    return [
      {
        text: "ID",
        value: "id",
        groupable: false,
        editable: false,
      },
      {
        text: "Nome",
        value: "nome",
        groupable: false,
        editable: false,
      },
      {
        text: "Cognome",
        value: "cognome",
        groupable: false,
        editable: false,
      },
      {
        text: "Email",
        value: "email",
        groupable: false,
        editable: false,
      },
      {
        text: "Sesso",
        value: "sesso",
        groupable: false,
        editable: false,
        sortable: false,
      },
      {
        text: "Data di nascita",
        value: "dataDiNascita",
        groupable: false,
        sort: (x: Date, y: Date) => +x - +y,

        editable: false,
        itemTextHandler: (value: Date) => value.toLocaleDateString(),
      },
      ...(this.canSeeAllDetails
        ? [
            {
              text: "Residenza",
              value: "residenza.comune",
              groupable: false,
              sortable: false,
              editable: false,
            },
            {
              text: "Cauzione",
              value: "possiedeCauzione",
              groupable: false,
              sortable: false,
              editable: false,
              itemText: false,
              itemIcon: true,
              itemIconHandler: (value: boolean) => (value ? "mdi-check" : ""),
              itemIconColour: () => "success",
            },
          ]
        : []),
    ];
  }

  get actions(): Actions<OspitiReturned> {
    return {
      onEdit: this.canEdit ? (item) => this.openEdit(item) : undefined,
      onDelete: this.isRoot ? (item) => this.askDelete(item) : undefined,
      others: [
        {
          icon: "mdi-file-document-outline",
          color: "warning",
          title: "Scarica documento",
          showAction: (item) => !!item.documentoIdentita?.documento,
          action: (item) => this.downloadDocumento(item),
        },
        {
          icon: "mdi-eye",
          color: "success",
          title: "Visualizza dettagli",
          action: (item) => {
            this.$router.push(`./ospiti/${item.id}`);
          },
        },
      ],
    };
  }

  /* METHODS */

  getIdFromValue(value: OspitiReturned): number {
    return value.id;
  }

  async deleteHandler(id: number, isMultiple: boolean): Promise<void> {
    await this.deleteOspite(id, isMultiple ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);
  }

  async createHandler(value: OspitiCreateBody): Promise<{ id: number; details: { foto: string | null; documento: string | null } }> {
    const id = await this.createOspite(value);

    let foto: string | null = null;
    if (this.foto instanceof File) {
      const formData = new FormData();
      formData.append("foto", this.foto);
      foto = await this.uploadOspiteFoto(id, formData, AlertType.ERROR_ALERT);
    }
    this.foto = null;

    let documento: string | null = null;
    if (this.documento instanceof File) {
      const formData = new FormData();
      formData.append("documento", this.documento);
      documento = await this.uploadOspiteDocumento(id, formData, AlertType.ERROR_ALERT);
    }
    this.documento = null;

    return { id, details: { foto, documento } };
  }

  async updateHandler(id: number, value: OspitiUpdateBody, isTableEdit: boolean): Promise<{ foto: string | null; documento: string | null }> {
    await this.updateOspite(id, value, isTableEdit ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);

    let foto: string | null = null;
    if (this.foto instanceof File) {
      const formData = new FormData();
      formData.append("foto", this.foto);
      foto = await this.uploadOspiteFoto(id, formData, AlertType.ERROR_ALERT);
    } else if (this.foto === null) {
      await this.deleteOspiteFoto(id, AlertType.ERROR_ALERT);
    } else {
      foto = this.foto;
    }
    this.foto = null;

    let documento: string | null = null;
    if (this.documento instanceof File) {
      const formData = new FormData();
      formData.append("documento", this.documento);
      documento = await this.uploadOspiteDocumento(id, formData, AlertType.ERROR_ALERT);
    } else if (this.documento === null) {
      await this.deleteOspiteDocumento(id, AlertType.ERROR_ALERT);
    } else {
      documento = this.documento;
    }
    this.documento = null;

    return { foto, documento };
  }

  updateBodyFromValue(value: OspitiReturned): OspitiUpdateBody {
    const luogoDiNascita = value.luogoDiNascita as LuogoDiNascita;
    delete (luogoDiNascita as any).id;

    const residenza = value.residenza as Residenza;
    delete (residenza as any).id;

    const domicili = value.domicili
      ? value.domicili.map((el) => {
          delete (el as any).id;
          delete (el as any).idPersona;
          return el;
        })
      : [];

    this.foto = value.foto ? this.$stored.getPath(value.foto) : null;
    this.documento = value.documentoIdentita?.documento ? this.$stored.getPath(value.documentoIdentita.documento) : null;

    return {
      nome: value.nome,
      cognome: value.cognome,
      codiceFiscale: value.codiceFiscale,
      sesso: value.sesso,
      dataDiNascita: value.dataDiNascita,
      email: value.email,
      telefonoPrincipale: value.telefonoPrincipale,
      telefonoSecondario: value.telefonoSecondario,
      cittadinanza: value.cittadinanza,
      documentoIdentita: value.documentoIdentita,
      codiceDipartimentoUnitn: value.codiceDipartimentoUnitn,
      contoCorrente: value.contoCorrente,
      luogoDiNascita: luogoDiNascita,
      residenza: residenza,
      domicili: domicili,
    };
  }
  tupleValueFromCreateBody(id: number, body: OspitiCreateBody, details: { foto: string | null; documento: string | null }): OspitiReturned {
    return {
      id,
      nome: body.nome,
      cognome: body.cognome,
      codiceFiscale: body.codiceFiscale ?? null,
      sesso: body.sesso,
      email: body.email,
      telefonoPrincipale: body.telefonoPrincipale,
      telefonoSecondario: body.telefonoSecondario,
      cittadinanza: body.cittadinanza,
      dataDiNascita: body.dataDiNascita,
      luogoDiNascita: body.luogoDiNascita as any,
      residenza: body.residenza as any,
      domicili: body.domicili ?? ([] as any),
      documentoIdentita: body.documentoIdentita ? { ...body.documentoIdentita, id: id, documento: details.documento } : null,
      contoCorrente: body.contoCorrente ? { ...body.contoCorrente, id: id } : null,
      codiceDipartimentoUnitn: body.codiceDipartimentoUnitn,
      idGiada: body.idGiada,
      foto: details.foto,
      poliziotto: body.poliziotto ?? false,
      possiedeCauzione: false,
      dataRestituzioneCauzione: null,
      dataCreazione: new Date(),
      eliminato: null,
    };
  }
  tupleValueFromUpdateBody(id: number, body: OspitiUpdateBody, backupValue: OspitiReturned, details: { foto: string | null; documento: string | null }): OspitiReturned {
    return {
      id,
      nome: body.nome as string,
      cognome: body.cognome as string,
      codiceFiscale: body.codiceFiscale ?? null,
      sesso: body.sesso as string,
      email: body.email as string,
      telefonoPrincipale: body.telefonoPrincipale ?? null,
      telefonoSecondario: body.telefonoSecondario ?? null,
      cittadinanza: body.cittadinanza as string,
      dataDiNascita: body.dataDiNascita as Date,
      luogoDiNascita: body.luogoDiNascita as any,
      residenza: body.residenza as any,
      domicili: body.domicili ?? ([] as any),
      documentoIdentita: body.documentoIdentita ? { ...body.documentoIdentita, id: id, documento: details.documento } : null,
      contoCorrente: body.contoCorrente ? { ...body.contoCorrente, id: id } : null,
      codiceDipartimentoUnitn: body.codiceDipartimentoUnitn ?? null,
      idGiada: body.idGiada ?? null,
      poliziotto: body.poliziotto ?? false,
      foto: details.foto,
      possiedeCauzione: backupValue.possiedeCauzione,
      dataRestituzioneCauzione: backupValue.dataRestituzioneCauzione,
      dataCreazione: new Date(),
      eliminato: null,
    };
  }

  async getTuplesFromTableOptions(tableOptions: DataOptions | null, tableSearch: string): Promise<[OspitiReturned[], number | null]> {
    const includeParams: OspitiIncludeParams = {
      persona: {
        luogoDiNascita: true,
        residenza: true,
        domicili: true,
      },
      documentoIdentita: true,
      contoCorrente: true,
    };

    let pageParams: OspitiPageParams = {};
    let orderByParams: OspitiOrderByParams = {
      orderBy: {
        persona: {},
      },
    };
    let searchParams: OspitiSearchParams = {
      search: tableSearch || undefined,
    };

    if (tableOptions) {
      pageParams = {
        page: tableOptions.itemsPerPage > 0 ? tableOptions.page : undefined,
        pageSize: tableOptions.itemsPerPage > 0 ? tableOptions.itemsPerPage : undefined,
      };

      tableOptions.sortBy.forEach((prop, index) => {
        if (["nome", "cognome"].includes(prop) && orderByParams?.orderBy?.persona) {
          orderByParams.orderBy.persona[prop] = (tableOptions.sortDesc[index] ? -1 : +1) * (index + 1);
        } else if (orderByParams?.orderBy) {
          orderByParams.orderBy[prop] = (tableOptions.sortDesc[index] ? -1 : +1) * (index + 1);
        }
      });
    }

    let count: number | null = null;
    let ospiti: OspitiReturned[] = [];

    try {
      this.loading = true;
      count = await this.countOspiti({ ...searchParams }, AlertType.ERRORS_QUEUE);
      ospiti = await this.getOspiti({ ...includeParams, ...pageParams, ...orderByParams, ...searchParams }, AlertType.ERRORS_QUEUE);
    } finally {
      this.loading = false;
    }

    return [ospiti, count];
  }

  async downloadDocumento(item: OspitiReturned): Promise<void> {
    await this.$stored.download(item.documentoIdentita?.documento as string, `documento_identita_${item.id}.pdf`);
  }

  syncCreateOpen(): void {
    if (this.createDialogOpen) {
      this.openCreate();
    } else {
      this.closeCreate(false);
    }
  }

  /* WATCHERS */

  @Watch("showCreateDialog")
  watchShowCreateDialog(): void {
    try {
      const { href } = this.$router.resolve({ path: this.$route.path, query: { ...this.$route.query, createDialogOpen: this.showCreateDialog.toString() } });
      history.replaceState({}, "", href);
    } catch (error) {}
  }

  /* LIFE CYCLE */

  async mounted() {
    try {
      this.loading = true;
      const [values, _] = await this.getTuplesFromTableOptions(this.tableOptions, this.tableSearch);
      this.values = values;
    } finally {
      this.loading = false;
    }

    this.syncCreateOpen();
  }
}
