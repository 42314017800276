


































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Stato, Provincia, Comune } from "operatn-api-client";

import { ActionTypes, AlertType } from "@/store";

import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";

interface Posto {
  stato: string;
  provincia: string | null;
  comune: string | null;
  istatComune: string | null;
  indirizzo?: string;
  nCivico?: string;
  cap?: string | null;
}

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {
    OperatnDateInput,
  },
})
export default class OperatnPosto extends Vue {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<Posto>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  @Prop({ type: Boolean, default: false })
  hasIndirizzo!: boolean;

  @Prop({ type: Boolean, default: false })
  foreignComuneRequired!: boolean;

  /* DATA */

  private stati: Stato[] = [];
  private province: Provincia[] = [];
  private comuni: Comune[] = [];

  private selectedComune: Comune | null = null;

  private isLoading = {
    stati: false,
    province: false,
    comuni: false,
  };

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  get isItalian(): boolean {
    return this.internalValue.stato === "IT";
  }

  /* METHODS */

  fixUndefinedData(): void {
    if (this.internalValue.provincia === undefined) {
      this.internalValue.provincia = null;
    }
    if (this.internalValue.comune === undefined) {
      this.internalValue.comune = null;
    }
    if (this.internalValue.istatComune === undefined) {
      this.internalValue.istatComune = null;
    }

    if (this.hasIndirizzo) {
      if (this.internalValue.indirizzo === undefined) {
        this.internalValue.indirizzo = '';
      }
      if (this.internalValue.nCivico === undefined) {
        this.internalValue.nCivico = '';
      }
      if (this.internalValue.cap === undefined) {
        this.internalValue.cap = null;
      }
    }
  }

  async fetchStati(): Promise<void> {
    try {
      this.isLoading.stati = true;
      this.stati = await this.$api.stati.getAll();
      this.stati = this.stati.sort((x, y) => (x.denominazione === y.denominazione ? 0 : x.denominazione < y.denominazione ? -1 : 1));
    } catch (error) {
      this.stati = [];
      this.$store.dispatch(ActionTypes.ALERT, { message: "Errore nel caricare gli stati", alertType: AlertType.ERRORS_QUEUE });
    } finally {
      this.isLoading.stati = false;
    }
  }

  async fetchProvince(): Promise<void> {
    try {
      this.isLoading.province = true;
      this.province = await this.$api.province.getAll();
      this.province = this.province.sort((x, y) => (x.denominazione === y.denominazione ? 0 : x.denominazione < y.denominazione ? -1 : 1));
      if (this.internalValue.provincia) {
        await this.fetchComuni(this.internalValue.provincia);
      }
    } catch (error) {
      this.province = [];
      this.$store.dispatch(ActionTypes.ALERT, { message: "Errore nel caricare gli province", alertType: AlertType.ERRORS_QUEUE });
    } finally {
      this.isLoading.province = false;
    }
  }

  async fetchComuni(provincia?: string): Promise<void> {
    if (provincia) {
      try {
        this.isLoading.comuni = true;
        this.comuni = (await this.$api.comuni.getAll()).filter((c) => c.siglaProvincia === provincia);
        if (this.internalValue.comune) {
          this.selectedComune = this.comuni.find((c) => c.denominazione === this.internalValue.comune) ?? null;
        }
      } catch (error) {
        this.comuni = [];
        this.$store.dispatch(ActionTypes.ALERT, { message: "Errore nel caricare i comuni", alertType: AlertType.ERRORS_QUEUE });
      } finally {
        this.isLoading.comuni = false;
      }
    } else {
      this.comuni = [];
    }
  }

  /* WATCHERS */

  @Watch("isItalian")
  async watchIsItalian() {
    if (!this.isItalian) {
      this.comuni = [];
      this.internalValue.provincia = null;
      this.selectedComune = null;
    }
  }

  @Watch("internalValue.provincia")
  async watchInternalValueResidenzaProvincia() {
    const provincia = this.internalValue.provincia;

    if (!provincia) {
      this.comuni = [];
    } else {
      this.fetchComuni(provincia);
    }
    this.selectedComune = null;
  }

  @Watch("selectedComune")
  async watchSelectedComune() {
    if (this.selectedComune) {
      this.internalValue.comune = this.selectedComune.denominazione;
      this.internalValue.istatComune = this.selectedComune.codiceIstat;
    } else {
      this.internalValue.istatComune = null;
    }
  }

  @Watch("internalValue", { deep: true })
  async watchInternalValue() {
    this.fixUndefinedData();
  }

  /* LIFE CYCLE */

  async mounted() {
    this.fixUndefinedData();
    await this.fetchStati();
    await this.fetchProvince();
  }
}
