






































import { Component, Prop, Mixins } from "vue-property-decorator";
import { OspitiReturned } from "operatn-api-client";

import CopyMixin from "@/mixins/CopyMixin";

@Component
export default class OperatnContoCorrenteInfo extends Mixins(CopyMixin) {
  /* PROPS */

  @Prop({ type: Object, required: true })
  ospite!: OspitiReturned;
}
