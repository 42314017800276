


















































import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import { OspitiCreateBody, OspitiUpdateBody, Stato } from "operatn-api-client";

import { ActionTypes, AlertType } from "@/store";

import OspiteHandlerMixin from "@/mixins/handlers/OspiteHandlerMixin";

import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";
import OperatnImageInput from "@/components/gears/inputs/OperatnImageInput.vue";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {
    OperatnDateInput,
    OperatnImageInput,
  },
})
export default class OperatnDatiOspite extends Mixins(OspiteHandlerMixin) {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<OspitiCreateBody> | Partial<OspitiUpdateBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  @Prop({ validator: (v) => v instanceof File || typeof v === "string" || v === null, default: null })
  foto!: File | string | null;

  /* DATA */

  private stati: Stato[] = [];

  private isLoading = {
    stati: false,
  };

  private emails: string[] = [];

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  get internalFoto() {
    return this.foto;
  }
  set internalFoto(value) {
    this.$emit("update:foto", value);
  }

  /* METHODS */

  async fetchStati(): Promise<void> {
    try {
      this.isLoading.stati = true;
      this.stati = await this.$api.stati.getAll();
      this.stati = this.stati.sort((x, y) => (x.denominazione === y.denominazione ? 0 : x.denominazione < y.denominazione ? -1 : 1));
    } catch (error) {
      this.stati = [];
      this.$store.dispatch(ActionTypes.ALERT, { message: "Errore nel caricare gli stati", alertType: AlertType.ERRORS_QUEUE });
    } finally {
      this.isLoading.stati = false;
    }
  }

  /* WATCHERS */

  @Watch("internalValue")
  watchInternalValue() {
    if (this.internalValue.telefonoPrincipale === "") {
      this.internalValue.telefonoPrincipale = null;
    }
    if (this.internalValue.telefonoSecondario === "") {
      this.internalValue.telefonoSecondario = null;
    }
  }

  /* LIFE CYCLE */

  async mounted() {
    await this.fetchStati();
    const emails = await this.getOspitiEmails(AlertType.ERRORS_QUEUE);
    this.emails = emails.filter((email) => email !== this.internalValue.email);
  }
}
