






























































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { OspitiReturned, ContrattiReturned, RuoloUtente, ContrattiCheckoutBody, ContrattiDateBody } from "operatn-api-client";

import { ActionTypes } from "@/store";

import OperatnBaseTable, { Column, Actions, RowColors } from "@/components/gears/bases/OperatnBaseTable.vue";
import OperatnActionDialog from "@/components/gears/dialogs/OperatnActionDialog.vue";
import OperatnContrattoCheckOutForm, { ContrattoCheckoutFormInfo } from "@/components/gears/forms/contratto/OperatnContrattoCheckOutForm.vue";
import OperatnContrattoCheckInForm, { ContrattoCheckinFormInfo } from "@/components/gears/forms/contratto/OperatnContrattoCheckInForm.vue";

import OspiteHandlerMixin from "@/mixins/handlers/OspiteHandlerMixin";
import ContrattoHandlerMixin from "@/mixins/handlers/ContrattoHandlerMixin";

interface HandledContratto {
  id: number;
  siglaTipoContratto: string;
  fabbricato: string;
  unitaImmobiliare: string;
  numeroStanza: string;
  postoLetto: string;
  dataInizio: Date;
  dataFine: Date;
  dataChiusuraAnticipata: Date | null;
  data: ContrattiReturned;
}

interface SelectedContratto {
  id: number;
  dataCheckin: Date | null;
  dataCheckout: Date | null;
  dataConsegnaLasciapassare: Date | null;
  dataConsegnaKit: Date | null;
  note: string | null;
  pdfQuestura: string | null;

  data: ContrattiReturned;
}

@Component({
  components: {
    OperatnBaseTable,
    OperatnActionDialog,
    OperatnContrattoCheckOutForm,
    OperatnContrattoCheckInForm,
  },
})
export default class OperatnOspiteContrattiInfo extends Mixins(OspiteHandlerMixin, ContrattoHandlerMixin) {
  /* PROPS */

  @Prop({ type: Object, required: true })
  ospite!: OspitiReturned;

  /* DATA */

  private contratti: ContrattiReturned[] = [];
  private selectedContratto: SelectedContratto | null = null;

  private checkoutBody: ContrattiCheckoutBody | null = null;
  private checkoutBodyValid = false;
  private showCheckOutDialog = false;
  private loadingCheckout = false;

  private checkinBody: ContrattiDateBody | null = null;
  private checkinBodyValid = false;
  private showCheckInDialog = false;
  private loadingCheckin = false;

  private loadingQuestura = false;

  private columns: Column<ContrattiReturned>[] = [
    {
      text: "ID",
      value: "id",
    },
    {
      text: "Contratto",
      value: "siglaTipoContratto",
    },
    {
      text: "Fabbricato",
      value: "fabbricato",
    },
    {
      text: "Interno",
      value: "unitaImmobiliare",
    },
    {
      text: "N. stanza",
      value: "numeroStanza",
    },
    {
      text: "Letto",
      value: "postoLetto",
    },
    {
      text: "Data inizio",
      value: "dataInizio",
      itemTextHandler: (item: Date) => item.toLocaleDateString(),
    },
    {
      text: "Data fine",
      value: "dataFine",
      itemTextHandler: (item: Date) => item.toLocaleDateString(),
    },
    {
      text: "C. anticipata",
      value: "dataChiusuraAnticipata",
      itemTextHandler: (item: Date | null) => (item ? item.toLocaleDateString() : "NO"),
    },
  ];
  private actions: Actions<ContrattiReturned> = {
    onView: this.canViewContractInfo ? (item) => `../contratti/${item.id}` : undefined,
  };

  /* GETTERS */

  get role(): RuoloUtente {
    return this.$store.getters.role;
  }

  get canViewContractInfo(): boolean {
    return [RuoloUtente.ROOT, RuoloUtente.ADMIN, RuoloUtente.SPORTELLO, RuoloUtente.RAGIONERIA].includes(this.role);
  }

  get canChangePresence(): boolean {
    return [RuoloUtente.ROOT, RuoloUtente.ADMIN, RuoloUtente.SPORTELLO, RuoloUtente.PORTINERIA].includes(this.role);
  }

  get handledContratti(): HandledContratto[] {
    return this.contratti
      .map((contratto) => ({
        id: contratto.id,
        siglaTipoContratto: contratto?.tipoContratto?.sigla ?? "-",
        fabbricato: contratto?.contrattiSuOspite?.[0]?.contrattiSuOspiteSuPostoLetto?.[0]?.postoLetto?.stanza?.fabbricato?.nome ?? "-",
        numeroStanza: contratto?.contrattiSuOspite?.[0]?.contrattiSuOspiteSuPostoLetto?.[0]?.postoLetto?.stanza?.numeroStanza ?? "-",
        unitaImmobiliare: contratto?.contrattiSuOspite?.[0]?.contrattiSuOspiteSuPostoLetto?.[0]?.postoLetto?.stanza?.unitaImmobiliare ?? "-",
        postoLetto: contratto?.contrattiSuOspite?.[0]?.contrattiSuOspiteSuPostoLetto?.[0]?.postoLetto?.postoLetto ?? "-",
        dataInizio: contratto.dataInizio,
        dataFine: contratto.dataFine,
        dataChiusuraAnticipata: contratto.dataChiusuraAnticipata,
        data: contratto,
      }))
      .sort((x, y) => y.id - x.id);
  }

  get displayCheckinDate(): string | null {
    return this.selectedContratto?.dataCheckin ? this.selectedContratto?.dataCheckin.toLocaleDateString() : null;
  }
  get displayCheckoutDate(): string | null {
    return this.selectedContratto?.dataCheckout ? this.selectedContratto?.dataCheckout.toLocaleDateString() : null;
  }
  get displayConsegnaLasciapassareDate(): string {
    return this.selectedContratto?.dataConsegnaLasciapassare ? this.selectedContratto?.dataConsegnaLasciapassare.toLocaleDateString() : "-";
  }
  get displayConsegnaKitDate(): string {
    return this.selectedContratto?.dataConsegnaKit ? this.selectedContratto?.dataConsegnaKit.toLocaleDateString() : "-";
  }

  get checkDialogInfo(): ContrattoCheckoutFormInfo | ContrattoCheckinFormInfo | null {
    return this.selectedContratto
      ? {
          personaNome: this.ospite.nome,
          personaCognome: this.ospite.cognome,
          fabbricato: this.selectedContratto.data.contrattiSuOspite?.[0]?.contrattiSuOspiteSuPostoLetto?.[0]?.postoLetto?.stanza?.fabbricato?.indirizzo ?? "-",
          stanza: this.selectedContratto.data.contrattiSuOspite?.[0]?.contrattiSuOspiteSuPostoLetto?.[0]?.postoLetto?.stanza?.numeroStanza ?? "-",
        }
      : null;
  }

  /* METHODS */

  rowBackgrounds(item: HandledContratto): RowColors {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    if (item.dataInizio <= now && (item.dataChiusuraAnticipata ?? item.dataFine) >= now) {
      return "soft-green";
    }

    if (item.id === this.selectedContratto?.id) {
      return "soft-gray";
    }

    return "";
  }

  async selectContratto(item: HandledContratto): Promise<void> {
    this.selectedContratto = await this.computeSelectedContratto(item.data);
  }

  async computeSelectedContratto(contratto: ContrattiReturned): Promise<SelectedContratto> {
    const contrattoSuOspite = contratto?.contrattiSuOspite?.[0] ?? null;

    let pdfQuestura: string | null = null;
    try {
      pdfQuestura = await this.getPdfQuestura(contratto.id, this.ospite.id);
    } catch (error) {}

    return {
      id: contratto.id,
      dataCheckin: contrattoSuOspite?.dataCheckin ?? null,
      dataCheckout: contrattoSuOspite?.dataCheckout ?? null,
      dataConsegnaLasciapassare: contrattoSuOspite?.dataConsegnaLasciapassare ?? null,
      dataConsegnaKit: contrattoSuOspite?.dataConsegnaKit ?? null,
      note: contrattoSuOspite?.note ?? null,
      pdfQuestura,
      data: contratto,
    };
  }

  cancelCheckIn(): void {
    this.$store.dispatch(ActionTypes.SHOW_CONFIRM_DIALOG, {
      text: "Sei sicuro di voler cancellare il check-in?",
      callback: async (answer: boolean) => {
        if (answer && this.selectedContratto) {
          await this.deleteCheckIn(this.selectedContratto.id, this.ospite.id);
          this.selectedContratto.dataCheckin = null;
          this.contratti = this.contratti.map((contratto) => {
            if (this.selectedContratto && contratto.id === this.selectedContratto.id && contratto.contrattiSuOspite?.[0]) {
              contratto.contrattiSuOspite[0].dataCheckin = null;
            }
            return contratto;
          });
        }
      },
    });
  }

  cancelCheckOut(): void {
    this.$store.dispatch(ActionTypes.SHOW_CONFIRM_DIALOG, {
      text: "Sei sicuro di voler cancellare il check-out?",
      callback: async (answer: boolean) => {
        if (answer && this.selectedContratto) {
          await this.deleteCheckOut(this.selectedContratto.id, this.ospite.id);

          this.selectedContratto.dataCheckout = null;
          this.selectedContratto.dataConsegnaLasciapassare = null;
          this.selectedContratto.dataConsegnaKit = null;
          this.selectedContratto.note = null;

          this.contratti = this.contratti.map((contratto) => {
            if (this.selectedContratto && contratto.id === this.selectedContratto.id && contratto.contrattiSuOspite?.[0]) {
              contratto.contrattiSuOspite[0].dataCheckout = null;
              contratto.contrattiSuOspite[0].dataConsegnaLasciapassare = null;
              contratto.contrattiSuOspite[0].dataConsegnaKit = null;
              contratto.contrattiSuOspite[0].note = null;
            }
            return contratto;
          });
        }
      },
    });
  }

  cancelCheckoutDialog(): void {
    this.showCheckOutDialog = false;
    this.checkoutBody = null;
    this.checkoutBodyValid = false;
  }
  async confirmCheckoutDialog(): Promise<void> {
    if (this.checkoutBodyValid && this.selectedContratto && this.checkoutBody) {
      try {
        this.loadingCheckout = true;
        await this.addCheckOut(this.selectedContratto.id, this.ospite.id, this.checkoutBody);
      } finally {
        this.loadingCheckout = false;
      }

      this.selectedContratto.dataCheckout = this.checkoutBody.data;
      this.selectedContratto.dataConsegnaLasciapassare = this.checkoutBody.dataConsegnaLasciapassare;
      this.selectedContratto.dataConsegnaKit = this.checkoutBody.dataConsegnaKit;
      this.selectedContratto.note = this.checkoutBody.note;

      this.contratti = this.contratti.map((contratto) => {
        if (this.selectedContratto && contratto.id === this.selectedContratto.id && contratto.contrattiSuOspite?.[0]) {
          contratto.contrattiSuOspite[0].dataCheckout = this.checkoutBody?.data as Date;
          contratto.contrattiSuOspite[0].dataConsegnaLasciapassare = this.checkoutBody?.dataConsegnaLasciapassare as Date;
          contratto.contrattiSuOspite[0].dataConsegnaKit = this.checkoutBody?.dataConsegnaKit as Date;
          contratto.contrattiSuOspite[0].note = this.checkoutBody?.note as string;
        }
        return contratto;
      });
    }

    this.cancelCheckoutDialog();
  }

  cancelCheckinDialog(): void {
    this.showCheckInDialog = false;
    this.checkinBody = null;
    this.checkinBodyValid = false;
  }

  async confirmCheckinDialog(): Promise<void> {
    if (this.checkinBodyValid && this.selectedContratto && this.checkinBody) {
      try {
        this.loadingCheckin = true;
        await this.addCheckIn(this.selectedContratto.id, this.ospite.id, this.checkinBody);
      } finally {
        this.loadingCheckin = false;
      }

      this.selectedContratto.dataCheckin = this.checkinBody.data;

      this.contratti = this.contratti.map((contratto) => {
        if (this.selectedContratto && contratto.id === this.selectedContratto.id && contratto.contrattiSuOspite?.[0]) {
          contratto.contrattiSuOspite[0].dataCheckin = this.checkinBody?.data as Date;
        }
        return contratto;
      });
    }

    this.cancelCheckinDialog();
  }

  async generaPdfQuestura(): Promise<void> {
    if (this.selectedContratto) {
      try {
        this.loadingQuestura = true;
        await this.postPdfQuestura(this.selectedContratto.id, this.ospite.id);
        this.selectedContratto.pdfQuestura = await this.getPdfQuestura(this.selectedContratto.id, this.ospite.id);
      }
      finally {
        this.loadingQuestura = false;
      }
    }
  }

  /* LIFE CYCLE */

  async mounted() {
    this.contratti = await this.getContratti({
      idOspite: this.ospite.id,
      tipoContratto: true,
      contrattiSuOspite: {
        contrattiSuOspiteSuPostoLetto: {
          postoLetto: {
            stanza: {
              fabbricato: true,
            },
          },
        },
      },
    });

    if (this.handledContratti.length) {
      this.selectContratto(this.handledContratti[0]);
    }
  }
}
