
import { Component, Vue } from "vue-property-decorator";
import { BadRequestError, InvalidBodyError, InvalidPathParamError, NotFoundError, OspitiReturned, OspitiCreateBody, OspitiIncludeParams, OspitiSearchParams, OspitiPageParams, OspitiFilterParams, OspitiUpdateBody, OspitiOrderByParams, OspitiRestituzioneCauzioneBody } from "operatn-api-client";

import { ActionTypes, AlertType } from "@/store";

@Component
export default class OspiteHandlerMixin extends Vue {

  /* METHODS */

  async countOspiti(params: OspitiSearchParams & OspitiFilterParams = {}, alertType = AlertType.ERROR_ALERT): Promise<number> {
    try {
      return this.$api.ospiti.count(params, { alertType });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile contare gli ospiti`, alertType });
      }
      throw error;
    }
  }

  async getOspiti(params: OspitiIncludeParams & OspitiOrderByParams & OspitiSearchParams & OspitiPageParams & OspitiFilterParams = {}, alertType = AlertType.ERROR_ALERT): Promise<OspitiReturned[]> {
    try {
      return this.$api.ospiti.getAll(params, { alertType });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile caricare gli ospiti`, alertType });
      }
      throw error;
    }
  }

  async getOspitiEmails(alertType = AlertType.ERROR_ALERT): Promise<string[]> {
    try {
      return this.$api.ospiti.getEmails({ alertType });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile caricare le email degli ospiti`, alertType });
      }
      throw error;
    }
  }

  async getOspite(id: number, params: OspitiIncludeParams = {}, alertType = AlertType.ERROR_ALERT): Promise<OspitiReturned> {
    try {
      return this.$api.ospiti.get(id, params, { alertType });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Ospite non trovato`, alertType });
      }
      throw error;
    }
  }

  async createOspite(body: OspitiCreateBody, alertType = AlertType.ERROR_ALERT): Promise<number> {
    try {
      const id = await this.$api.ospiti.create(body, { alertType });
      return id;
    } catch (error) {
      if (error) {
        if (error instanceof InvalidBodyError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Dati dell'ospite da creare non validi`, alertType });
        } else if (error instanceof BadRequestError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida`, alertType });
        } else {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Errore del server`, alertType });
        }
      }
      throw error;
    }
  }

  async updateOspite(id: number, body: OspitiUpdateBody, alertType = AlertType.ERROR_ALERT): Promise<void> {
    try {
      await this.$api.ospiti.update(id, body, { alertType });
    } catch (error) {
      if (error) {
        if (error instanceof InvalidBodyError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Dati dell'ospite da modificare non validi`, alertType });
        } else if (error instanceof BadRequestError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida`, alertType });
        } else {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Errore del server`, alertType });
        }
      }
      throw error;
    }
  }

  async uploadOspiteFoto(id: number, formData: FormData, alertType = AlertType.ERROR_ALERT): Promise<string> {
    try {
      return await this.$api.ospiti.uploadFoto(id, formData, { alertType });
    } catch (error) {
      if (error instanceof NotFoundError) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Ospite non trovato`, alertType });
      } else if (error instanceof BadRequestError) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida`, alertType });
      } else {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Errore del server`, alertType });
      }
      throw error;
    }
  }

  async uploadOspiteDocumento(id: number, formData: FormData, alertType = AlertType.ERROR_ALERT): Promise<string> {
    try {
      return await this.$api.ospiti.uploadDocumento(id, formData, { alertType });
    } catch (error) {
      if (error instanceof NotFoundError) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Ospite non trovato`, alertType });
      } else if (error instanceof BadRequestError) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida`, alertType });
      } else {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Errore del server`, alertType });
      }
      throw error;
    }
  }

  async restituisciCauzioneOspite(id: number, body: OspitiRestituzioneCauzioneBody, alertType = AlertType.ERROR_ALERT): Promise<void> {
    try {
      await this.$api.ospiti.restituisciCauzione(id, body, { alertType });
    } catch (error) {
      if (error) {
        if (error instanceof InvalidBodyError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Dati dell'ospite da modificare non validi`, alertType });
        } else if (error instanceof BadRequestError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Probabilmente l'ospite possiede già una cauzione`, alertType });
        } else {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Errore del server`, alertType });
        }
      }
      throw error;
    }
  }

  async deleteOspiteFoto(id: number, alertType = AlertType.ERROR_ALERT): Promise<void> {
    try {
      return await this.$api.ospiti.deleteFoto(id, { alertType });
    } catch (error) {
      if (error instanceof NotFoundError) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Ospite non trovato`, alertType });
      } else if (error instanceof BadRequestError) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida`, alertType });
      } else {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Errore del server`, alertType });
      }
      throw error;
    }
  }

  async deleteOspiteDocumento(id: number, alertType = AlertType.ERROR_ALERT): Promise<void> {
    try {
      return await this.$api.ospiti.deleteDocumento(id, { alertType });
    } catch (error) {
      if (error instanceof NotFoundError) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Ospite non trovato`, alertType });
      } else if (error instanceof BadRequestError) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida`, alertType });
      } else {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Errore del server`, alertType });
      }
      throw error;
    }
  }

  async deleteOspite(id: number, alertType = AlertType.ERROR_ALERT): Promise<void> {
    try {
      await this.$api.ospiti.delete(id, { alertType });
    } catch (error) {
      if (error) {
        if (error instanceof InvalidPathParamError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Id ospite non valido`, alertType });
        } else if (error instanceof NotFoundError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Ospite non trovato`, alertType });
        } else if (error instanceof BadRequestError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida.`, alertType });
        }
      }
      throw error;
    }
  }

}