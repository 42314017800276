
import { Component, Vue } from "vue-property-decorator";
import { BadRequestError, InvalidBodyError, InvalidPathParamError, NotFoundError, TipoDocumentoIdentita, TipiDocumentoIdentitaCreateBody, TipiDocumentoIdentitaReplaceBody } from "operatn-api-client";

import { ActionTypes, AlertType } from "@/store";

@Component
export default class TipoDocumentoIdentitaHandlerMixin extends Vue {

  /* METHODS */

  async getTipiDocumentoIdentita(alertType = AlertType.ERROR_ALERT): Promise<TipoDocumentoIdentita[]> {
    try {
      return await this.$api.tipiDocumentoIdentita.getAll({ alertType });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile caricare i tipi documento identità`, alertType });
      }
      throw error;
    }
  }

  async createTipoDocumentoIdentita(body: TipiDocumentoIdentitaCreateBody, alertType = AlertType.ERROR_ALERT): Promise<number> {
    try {
      const id = await this.$api.tipiDocumentoIdentita.create(body, { alertType });
      return id;
    } catch (error) {
      if (error) {
        if (error instanceof InvalidBodyError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Dati del tipo di documento identità da creare non validi`, alertType });
        } else if (error instanceof BadRequestError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida`, alertType });
        } else {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Errore del server`, alertType });
        }
      }
      throw error;
    }
  }

  async updateTipoDocumentoIdentita(id: number, body: TipiDocumentoIdentitaReplaceBody, alertType = AlertType.ERROR_ALERT): Promise<void> {
    try {
      await this.$api.tipiDocumentoIdentita.replace(id, { tipoDocumentoIdentita: body.tipoDocumentoIdentita }, { alertType });
    } catch (error) {
      if (error) {
        if (error instanceof InvalidPathParamError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Id tipo documento identità non valido`, alertType });
        } else if (error instanceof InvalidBodyError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Dati da aggiornare del tipo documento identità non validi`, alertType });
        } else if (error instanceof NotFoundError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Tipo documento identità non trovato`, alertType });
        } else if (error instanceof BadRequestError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida.`, alertType });
        }
      }
      throw error;
    }
  }

  async deleteTipoDocumentoIdentita(id: number, alertType = AlertType.ERROR_ALERT): Promise<void> {
    try {
      await this.$api.tipiDocumentoIdentita.delete(id, { alertType });
    } catch (error) {
      if (error) {
        if (error instanceof InvalidPathParamError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Uid tipo documento identità non valido`, alertType });
        } else if (error instanceof NotFoundError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Tipo documento identità non trovato`, alertType });
        } else if (error instanceof BadRequestError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida.`, alertType });
        }
      }
      throw error;
    }
  }

  getTipiDocumentoIdentitaValues(values: TipoDocumentoIdentita[], backupValue: TipoDocumentoIdentita | null): string[] {
    const tipi = values.map((t) => t.tipoDocumentoIdentita);
    return backupValue ? tipi.filter((value) => value !== backupValue.tipoDocumentoIdentita) : tipi;
  }
}