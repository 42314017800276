


























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { PresidentiCreateBody, PresidentiUpdateBody, OspitiCreateBody, OspitiUpdateBody, Domicilio } from "operatn-api-client";

import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";
import OperatnActionDialog from "@/components/gears/dialogs/OperatnActionDialog.vue";
import OperatnBaseTable, { Actions, Column } from "@/components/gears/bases/OperatnBaseTable.vue";
import OperatnPosto from "./OperatnPosto.vue";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {
    OperatnDateInput,
    OperatnActionDialog,
    OperatnBaseTable,
    OperatnPosto,
  },
})
export default class OperatnDomicili extends Vue {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<PresidentiCreateBody> | Partial<PresidentiUpdateBody> | Partial<OspitiCreateBody> | Partial<OspitiUpdateBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  /* DATA */

  private columns: Column[] = [
    {
      text: "Stato",
      value: "stato",
      groupable: true,
      editable: false,
    },
    {
      text: "Provincia",
      value: "provincia",
      groupable: true,
      editable: false,
      itemTextHandler: (value: string | null) => (value === null ? "ESTERO" : value),
    },
    {
      text: "Comune",
      value: "comune",
      groupable: false,
      editable: false,
    },
    {
      text: "Indirizzo",
      value: "indirizzo",
      groupable: false,
      editable: false,
    },
    {
      text: "N. Civico",
      value: "nCivico",
      groupable: false,
      editable: false,
    },
  ];

  private actions: Actions<Omit<Domicilio, "id" | "idPersona">> = {
    onEdit: (_, index) => this.openEditDialog(index),
    onDelete: (_, index) => {
      if (this.internalValue.domicili) {
        this.internalValue.domicili.splice(index, 1);
      }
    },
  };

  private createBody: Omit<Domicilio, "id" | "idPersona"> | null = null;
  private createDialogShow = false;
  private createDialogValid = false;

  private editBody: Omit<Domicilio, "id" | "idPersona"> | null = null;
  private editId: number | null = null;
  private editDialogShow = false;
  private editDialogValid = false;

  /* GETTERS AND SETTERS */

  get domicili(): Omit<Domicilio, "id" | "idPersona">[] {
    return this.internalValue.domicili as Omit<Domicilio, "id" | "idPersona">[];
  }

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(_value) {
    this.$emit("update:formValid", true);
  }

  /* METHODS */

  getEmptyDomicilio(): Omit<Domicilio, "id" | "idPersona"> {
    return {
      stato: "",
      provincia: null,
      comune: "",
      istatComune: null,
      cap: null,
      indirizzo: "",
      nCivico: "",
    };
  }

  openCreateDialog() {
    this.createDialogShow = true;
    this.createBody = this.getEmptyDomicilio();
  }
  closeCreateDialog() {
    this.createDialogShow = false;
    this.createBody = null;
  }
  createDialogCancel() {
    this.closeCreateDialog();
  }
  createDialogConfirm() {
    if (this.internalValue.domicili && this.createBody) {
      this.internalValue.domicili.push(this.createBody);
    }
    this.closeCreateDialog();
  }

  openEditDialog(id: number) {
    this.editDialogShow = true;
    this.editBody = { ...this.domicili[id] };
    this.editId = id;
  }
  closeEditDialog() {
    this.editDialogShow = false;
    this.editBody = null;
    this.editId = null;
  }
  editDialogCancel() {
    this.closeEditDialog();
  }
  editDialogConfirm() {
    if (this.internalValue.domicili && this.editBody && this.editId !== null) {
      this.internalValue.domicili.splice(this.editId, 1, this.editBody);
    }
    this.closeEditDialog();
  }

  /* WATCHERS */

  @Watch('internalValue')
  watchInternalValue() {
    if (this.internalValue.domicili) {
      this.internalValue.domicili.map(d => {
        d.provincia = d.provincia ?? null;
        d.istatComune = d.istatComune ?? null;
        d.cap = d.cap ?? null;
        return d;
      })
    }
  }

  /* LIFE CYCLE */

  created() {
    this.internalFormValid = true;
  }
}
