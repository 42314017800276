



































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { PresidentiCreateBody, PresidentiUpdateBody, OspitiCreateBody, OspitiUpdateBody } from "operatn-api-client";

import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {
    OperatnDateInput,
  },
})
export default class OperatnDatiPersona extends Vue {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<PresidentiCreateBody> | Partial<PresidentiUpdateBody> | Partial<OspitiCreateBody> | Partial<OspitiUpdateBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  /* WATCHERS */

  @Watch("internalValue", { deep: true, immediate: true })
  watchInternalValue() {
    if (this.internalValue.codiceFiscale === "") {
      this.internalValue.codiceFiscale = null;
    }
  }
}
