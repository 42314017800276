




















































import Vue from "vue";
import { Component, Prop, Ref } from "vue-property-decorator";

@Component({
  model: {
    prop: "value",
    event: "change",
  },
})
export default class OperatnImageInput extends Vue {
  /* PROPS */

  @Prop({ validator: (v) => typeof v === "string" || v instanceof File || v === null, required: true })
  value!: File | string | null;

  @Prop({ type: String, default: "IMMAGINE" })
  label!: string;

  /* REFS */

  @Ref("cropper")
  cropperEl!: any;

  /* DATA */

  private dialogShown = false;
  private selectedFile: File | null = null;
  private toCropFile: string | null = null;

  /* GETTERS AND SETTERS */

  get internalValue(): File | string | null {
    return this.value;
  }
  set internalValue(value: File | string | null) {
    this.$emit("change", value);
  }

  get imagePreview(): string | null {
    if (this.internalValue === null) {
      return null;
    } else if (typeof this.internalValue === "string") {
      return this.internalValue;
    } else {
      return URL.createObjectURL(this.internalValue);
    }
  }

  get internalSelectedFile(): File | null {
    return this.selectedFile;
  }
  set internalSelectedFile(value: File | null) {
    this.selectedFile = value;

    if (this.selectedFile) {
      this.toCropFile = URL.createObjectURL(this.selectedFile);
    } else {
      this.toCropFile = null;
    }
  }

  /* METHODS */

  async showDialog(): Promise<void> {
    if (this.internalValue === null) {
      this.internalSelectedFile = null;
    } else if (typeof this.internalValue === "string") {
      const response = await fetch(this.internalValue);
      const data = await response.blob();
      const metadata = {
        type: "image/png",
      };
      this.internalSelectedFile = new File([data], `${this.label}.png`, metadata);
    } else {
      this.internalSelectedFile = this.internalValue;
    }
    this.dialogShown = true;
  }
  closeDialog(): void {
    this.selectedFile = null;
    this.dialogShown = false;
  }
  deleteDialog(): void {
    this.internalSelectedFile = null;
  }
  saveDialog(): void {
    if (this.toCropFile) {
      const { canvas } = this.cropperEl.getResult();
      canvas.toBlob((blob: Blob) => {
        this.internalValue = new File([blob], `${this.label}.png`, { type: "image/png" });
      }, "image/png");
    } else {
      this.internalValue = null;
    }
    this.closeDialog();
  }
}
