
































































































import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { ActionTypes, AlertType } from "@/store";

import CopyMixin from "@/mixins/CopyMixin";

@Component
export default class OperatnPostoInfo extends Mixins(CopyMixin) {
  /* PROPS */

  @Prop({ type: Object, required: true })
  posto!: any;

  @Prop({ type: Boolean, default: false })
  showIndirizzo!: boolean;

  /* DATA */

  private stato = this.posto.stato;

  /* METHODS */

  async updateStato(): Promise<void> {
    if (this.posto.stato) {
      try {
        const stato = await this.$api.stati.get(this.posto.stato);
        this.stato = stato.denominazione;
      } catch (error) {
        this.stato = this.posto.stato;
        this.$store.dispatch(ActionTypes.ALERT, { message: `Errore nel caricare lo stato ${this.stato}`, alertType: AlertType.ERRORS_QUEUE });
      }
    }
  }

  /* WATCHERS */

  @Watch("posto.stato")
  async watchPersonaResidenzaStato(): Promise<void> {
    await this.updateStato();
  }

  /* LIFE CYCLE */
  
  async mounted(): Promise<void> {
    await this.updateStato();
  }
}
