




























import { Component, Prop, Mixins } from "vue-property-decorator";
import { MutationTypes } from "@/store";

import VersionHandlerMixin from "@/mixins/handlers/VersionHandlerMixin";

import CONFIG from "@/config";

export interface MenuItem {
  icon: string;
  text: string;
  path: string;
}

@Component
export default class OperatnBaseMenu extends Mixins(VersionHandlerMixin) {
  /* PROPS */

  @Prop({ type: Array, required: true })
  private items!: MenuItem[];

  /* DATA */

  private tab = 0;
  private search = "";
  private siteVersion = CONFIG.VERSION;
  private apiVersion = "-";

  /* COMPUTED */

  get showMenu(): boolean {
    return this.$store.state.showMenu;
  }
  set showMenu(value: boolean) {
    this.$store.commit(MutationTypes.SET_SHOW_MENU, value);
  }

  get shownItems(): MenuItem[] {
    return this.search ? this.items.filter((item) => item.text.toUpperCase().indexOf(this.search.toUpperCase()) !== -1) : this.items;
  }

  /* LIFE CYCLE */

  async mounted() {
    this.apiVersion = await this.getApiVersion();
  }
}
