




import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { PresidentiCreateBody, PresidentiUpdateBody, OspitiCreateBody, OspitiUpdateBody, Residenza } from "operatn-api-client";

import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";
import OperatnPosto from "./OperatnPosto.vue";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {
    OperatnDateInput,
    OperatnPosto,
  },
})
export default class OperatnResidenza extends Vue {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<PresidentiCreateBody> | Partial<PresidentiUpdateBody> | Partial<OspitiCreateBody> | Partial<OspitiUpdateBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  /* METHODS */

  getEmptyResidenza(): Omit<Residenza, "id"> {
    return {
      stato: "",
      provincia: null,
      comune: null,
      istatComune: null,
      cap: null,
      indirizzo: "",
      nCivico: "",
    };
  }

  /* WATCHERS */

  @Watch("internalValue.residenza", { deep: true, immediate: true })
  async watchInternalValueResidenza() {
    if (!this.internalValue.residenza) {
      this.internalValue.residenza = this.getEmptyResidenza();
    }
  }

}
