




import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import * as pdfjs from 'pdfjs-dist/build/pdf';
import * as pdfjsWorker  from 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

@Component
export default class OperatnPdfPreview extends Vue {
  /* PROPS */

  @Prop({ type: String, required: true })
  src!: string;

  /* GETTERS */

  get canvasRef(): any {
    return this.$refs.canvas;
  }

  get canvasContext(): CanvasRenderingContext2D {
    return this.canvasRef.getContext("2d");
  }

  /* METHODS */

  async renderPreview(): Promise<void> {
      // Fetch pdf document
      const pdfDocument = await pdfjs.getDocument(this.src).promise;
      // Fetch pdf first page
      const firstPage = await pdfDocument.getPage(1);
      // Get viewport and set canvas
      const viewport = firstPage.getViewport({scale: 1});
      this.canvasRef.width = viewport.width;
      this.canvasRef.height = viewport.height;
      // Render pdf first page on canvas
      await firstPage.render({ canvasContext: this.canvasContext, viewport }).promise
  }

  /* EVENTCYCLE */

  mounted() {
      this.renderPreview();
  }

  /* WATCHERS */

  @Watch('src')
  watchSrc() {
      this.renderPreview();
  }
}
