


































import { Component, Mixins } from "vue-property-decorator";
import { OspitiReturned } from "operatn-api-client";

import ApiUnitnHandlerMixin from "@/mixins/handlers/ApiUnitnHandlerMixin";

@Component
export default class OperatnSearchOspiteWithApi extends Mixins(ApiUnitnHandlerMixin) {
  /* DATA */

  private isFormValid = false;
  private loading = false;
  private name = "";
  private surname = "";
  private ospiti: Partial<OspitiReturned>[] = [];

  private headers = [
    {
      text: "Id UniTN",
      value: "idGiada"
    },
    {
      text: "Nome",
      value: "nome",
    },
    {
      text: "Cognome",
      value: "cognome",
    },
    {
      text: "Data di nascita",
      value: "dataDiNascita",
    },
    {
      text: "Codice fiscale",
      value: "codiceFiscale",
    },
  ];

  /* GETTERS */

  get disabled(): boolean {
    return this.loading || !this.isFormValid;
  }

  /* METHODS */

  async searchOspiti(): Promise<void> {
    if (!this.disabled) {
      try {
        this.loading = true;
        this.ospiti = await this.searchOspitiWithApi(this.name, this.surname);
      } catch (error) {
        this.ospiti = [];
      } finally {
        this.loading = false;
      }
    }
  }

  selectOspite(event: any): void {
    this.$emit("selectOspite", event);
  }
}
