
import { Component, Vue } from "vue-property-decorator";
import { BadRequestError, InvalidBodyError, InvalidPathParamError, NotFoundError, Portineria, PortinerieCreateBody, PortinerieReplaceBody } from "operatn-api-client";

import { ActionTypes, AlertType } from "@/store";

@Component
export default class PortineriaHandlerMixin extends Vue {

  /* METHODS */

  async getPortinerie(alertType = AlertType.ERROR_ALERT): Promise<Portineria[]> {
    try {
      return await this.$api.portinerie.getAll({ alertType });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile caricare i portinerie`, alertType });
      }
      throw error;
    }
  }

  async getPortineriaByName(value: string, alertType = AlertType.ERROR_ALERT): Promise<Portineria> {
    try {
      return await this.$api.portinerie.getByName(value, { alertType });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Portineria ${value} non trovata`, alertType });
      }
      throw error;
    }
  }

  async createPortineria(body: PortinerieCreateBody, alertType = AlertType.ERROR_ALERT): Promise<number> {
    try {
      const id = await this.$api.portinerie.create(body, { alertType });
      return id;
    } catch (error) {
      if (error) {
        if (error instanceof InvalidBodyError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Dati della portineria da creare non validi`, alertType });
        } else if (error instanceof BadRequestError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida`, alertType });
        } else {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Errore del server`, alertType });
        }
      }
      throw error;
    }
  }

  async updatePortineria(id: number, body: PortinerieReplaceBody, alertType = AlertType.ERROR_ALERT): Promise<void> {
    try {
      await this.$api.portinerie.replace(id, {
       nome: body.nome
      }, { alertType });
    } catch (error) {
      if (error) {
        if (error instanceof InvalidPathParamError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Id quietanziante non valido`, alertType });
        } else if (error instanceof InvalidBodyError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Dati da aggiornare del quietanziante non validi`, alertType });
        } else if (error instanceof NotFoundError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Portineria non trovata`, alertType });
        } else if (error instanceof BadRequestError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida.`, alertType });
        }
      }
      throw error;
    }
  } 

  async deletePortineria(id: number, alertType = AlertType.ERROR_ALERT): Promise<void> {
    try {
      await this.$api.portinerie.delete(id, { alertType });
    } catch (error) {
      if (error) {
        if (error instanceof InvalidPathParamError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Uid quietanziante non valido`, alertType });
        } else if (error instanceof NotFoundError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Portineria non trovata`, alertType });
        } else if (error instanceof BadRequestError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida.`, alertType });
        } else {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Errore generico`, alertType });
        }
      }
      throw error;
    }
  }

  getPortinerieValues(values: Portineria[], backupValue: Portineria | null): string[] {
    const portinerie = values.map((t) => t.nome);
    return backupValue ? portinerie.filter((value) => value !== backupValue.nome) : portinerie;
  }

}