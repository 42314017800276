











































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { OspitiReturned, PresidentiReturned } from "operatn-api-client";

import CopyMixin from "@/mixins/CopyMixin";

@Component
export default class OperatnPersonaInfo extends Mixins(CopyMixin) {
  /* PROPS */

  @Prop({ type: Object, required: true })
  persona!: OspitiReturned | PresidentiReturned;

  /* GETTERS */

  get dataDiNascita(): string {
    return this.persona.dataDiNascita.toLocaleDateString();
  }
}
