




















import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ContrattiDateBody } from "operatn-api-client";

import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";

export interface ContrattoCheckinFormInfo {
  personaNome: string;
  personaCognome: string;
  fabbricato: string;
  stanza: string;
}

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {
    OperatnDateInput,
  },
})
export default class OperatnContrattoCheckInForm extends Vue {
  /* PROPS */

  @Prop({ validator: (v) => v instanceof Object || v === null, default: null })
  value!: ContrattiDateBody | null;

  @Prop({ type: Object, required: true })
  info!: ContrattoCheckinFormInfo;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  /* METHODS */

  getEmptyValue(): ContrattiDateBody {
    return {
      data: new Date(),
    };
  }

  /* LIFE CYCLE */

  created() {
    if (this.value === null) {
      this.internalValue = this.getEmptyValue();
    }
  }

  /* WATCH */

  @Watch("value", { deep: true, immediate: true })
  watchValue() {
    if (this.value === null) {
      this.internalValue = this.getEmptyValue();
    }
  }
}
