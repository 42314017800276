
















































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { DataOptions, DataTableHeader, InputValidationRule } from "vuetify";

export interface Column<T = any> extends DataTableHeader {
  editable?: boolean;
  onEditCancel?: (value: T) => void | Promise<void>;
  onEditClose?: (value: T) => void | Promise<void>;
  onEditSave?: (value: T) => void | Promise<void>;
  onEditOpen?: (value: T) => void | Promise<void>;
  editInput?: {
    type?: string;
    label?: string;
    hint?: string;
    counter?: boolean;
    clearable?: boolean;
    items?: any[];
    itemText?: string | ((x: any) => string);
    itemValue?: string;
    rules?: InputValidationRule[];
  };
  itemText?: boolean;
  itemIcon?: boolean;
  itemTextHandler?: (value: any) => string;
  itemIconHandler?: (value: any) => string;
  itemIconColour?: (value: any) => string;
}

export type RowColors = "" | "soft-red" | "soft-green" | "soft-blue" | "soft-orange" | "soft-gray";

export interface Actions<T = any> {
  onEdit?: (value: T, index: number) => void | Promise<void>;
  onDelete?: (value: T, index: number) => void | Promise<void>;
  onView?: (value: T, index: number) => string | Promise<string>;

  showEdit?: (value: T, index: number) => boolean;
  showDelete?: (value: T, index: number) => boolean;
  showView?: (value: T, index: number) => boolean;

  others?: {
    icon: string;
    color?: string;
    title?: string;
    showAction?: (value: T, index: number) => boolean;
    action: (value: T, index: number) => void | Promise<void>;
  }[];
}

export interface GroupHeaders {
  keyHandler?: (key: string) => string;
  valueHandler?: (value: string, key: string) => string;
}

type HandledColumn<T = any> = Column<T> & { actions: Actions<T> };

@Component({
  model: {
    prop: "selectedValues",
    event: "selected",
  },
})
export default class OperatnBaseTable extends Vue {
  /* PROPS */

  @Prop({ type: String, required: true })
  title!: string;

  @Prop({ type: Array, required: true })
  columns!: Column[];

  @Prop({ type: Object, required: false })
  actions?: Actions;

  @Prop({ type: Object, required: false })
  groupHeaders?: GroupHeaders;

  @Prop({ type: Array, default: () => [] })
  selectedValues!: any[];

  @Prop({ type: Array, required: true })
  values!: any[];

  @Prop({ type: String, required: false })
  itemKey?: string;

  @Prop({ type: Boolean, default: false })
  showSelect!: boolean;

  @Prop({ type: [String, Array], required: false })
  sortBy?: string | string[];

  @Prop({ type: [String, Array], required: false })
  aggregateBy?: string | string[];

  @Prop({ type: Boolean, required: false })
  sortDesc?: boolean;

  @Prop({ type: String, required: false })
  tableSearch?: string;

  @Prop({ type: Boolean, default: false })
  multiSort!: boolean;

  @Prop({ validator: (v) => typeof v === "object" || v === null, required: false })
  updateBody?: any;

  @Prop({ type: Boolean, default: false })
  loading!: boolean;

  @Prop({ type: Boolean, default: true })
  showTitle!: boolean;

  @Prop({ type: Number, default: 1 })
  elevation!: number;

  @Prop({ type: Function, default: () => "" })
  rowBackgrounds!: (item: any) => RowColors;

  @Prop({ type: Number, required: false })
  serverItemsLength?: number;

  @Prop({ type: Object, default: () => ({ itemsPerPage: 15 }) })
  options!: DataOptions;

  @Prop({ type: Array, default: () => [5, 10, 15, -1] })
  itemsPerPageOptions!: number[];

  /* DATA */

  private search: string | null = null;

  /* GETTERS AND SETTERS */

  get elevationClass(): string {
    return `elevation-${this.elevation}`;
  }

  get internalSelectedValues(): any {
    return this.selectedValues;
  }
  set internalSelectedValues(value: any) {
    this.$emit("selected", value);
  }

  get internalUpdateBody(): any {
    return this.updateBody;
  }
  set internalUpdateBody(value: any) {
    this.$emit("update:update-body", value);
  }

  get handledColumns(): (HandledColumn | Column)[] {
    return [
      ...this.columns,
      ...(this.actions
        ? [
            {
              text: "Azioni",
              value: "_actions",
              sortable: false,
              actions: {
                onEdit: this.actions.onEdit,
                onDelete: this.actions.onDelete,
                onView: this.actions.onView,
                showEdit: this.actions.showEdit ?? (() => !!this.actions?.onEdit),
                showDelete: this.actions.showDelete ?? (() => !!this.actions?.onDelete),
                showView: this.actions.showView ?? (() => !!this.actions?.onView),
                others: this.actions?.others,
              },
            },
          ]
        : []),
    ];
  }

  get internalAggregateBy(): string | string[] | null {
    return this.aggregateBy ?? null;
  }
  set internalAggregateBy(value: string | string[] | null) {
    this.$emit("update:aggregateBy", value);
  }

  get internalOptions(): DataOptions {
    return this.options;
  }
  set internalOptions(value: DataOptions) {
    this.$emit("update:options", value);
  }

  get internalSearch(): string | null {
    return this.tableSearch !== undefined ? this.tableSearch : this.search;
  }
  set internalSearch(value: string | null) {
    if (this.tableSearch !== undefined) {
      this.$emit("update:table-search", value);
    } else {
      this.search = value;
    }
  }
  get searchForTable(): string | null | undefined {
    return this.tableSearch !== undefined ? undefined : this.search;
  }

  /* METHODS */

  getHeaderSlotName(name: string): string {
    return `header.${name}`;
  }
  getItemSlotName(name: string): string {
    return `item.${name}`;
  }

  rowClicked(item: any): void {
    this.$emit("rowClicked", item);
  }
}
