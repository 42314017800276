





















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  model: {
    prop: "show",
    event: "show-updated",
  },
})
export default class OperatnActionDialog extends Vue {
  /* PROPS */

  @Prop({ type: String, default: "primary" })
  color!: string;

  @Prop({ type: String, default: "Attenzione" })
  title!: string;

  @Prop({ type: Boolean })
  show!: boolean;

  @Prop({ type: Boolean, default: true })
  persistent!: boolean;

  @Prop({ type: Boolean, required: true })
  disabled!: boolean;

  @Prop({ type: String, default: '50vw' })
  width!: string;

  @Prop({ type: String, default: '80vw' })
  widthMobile!: string;

  /* GETTERS AND SETTERS */

  get internalShow(): boolean {
    return this.show;
  }
  set internalShow(value: boolean) {
    this.$emit("show-updated", value);
  }

  /* METHODS */

  cancel(): void {
    this.internalShow = false;
    this.$emit('cancel');
  }

  confirm(): void {
    this.$emit('confirm');
  }
}
