




















import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { OspitiCreateBody, OspitiUpdateBody } from "operatn-api-client";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {},
})
export default class OperatnContoCorrente extends Vue {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<OspitiCreateBody> | Partial<OspitiUpdateBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  /* DATA */

  private iban = "";
  private banca = "";
  private localIsFormValid = false;

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  /* METHODS */

  initialAssignation(): void {
    if (this.internalValue.contoCorrente?.iban) {
      this.iban = this.internalValue.contoCorrente.iban;
    }
    if (this.internalValue.contoCorrente?.banca) {
      this.banca = this.internalValue.contoCorrente.banca;
    }

    this.inputChanged();
  }

  inputChanged(): void {
    if (!this.iban && !this.banca) {
      this.internalValue.contoCorrente = null;
      this.internalFormValid = true;
    } else {
      this.internalValue.contoCorrente = {
        iban: this.iban ?? null,
        banca: this.banca ?? null,
      };
      this.internalFormValid = this.localIsFormValid;
    }
  }

  /* WATCHERS */

  @Watch("iban")
  watchIban() {
   this.inputChanged();
  }
  @Watch("banca")
  watchBanca() {
   this.inputChanged();
  }
  @Watch('internalValue')
  watchInternalValue() {
    this.initialAssignation();
  }
  @Watch("localIsFormValid")
  watchLocalIsFormValid() {
   if (this.internalValue.contoCorrente) {
     this.internalFormValid = this.localIsFormValid;
   }
  }

  /* LIFE CYCLE */

  created() {
    this.initialAssignation();
    this.inputChanged();
  }
}
