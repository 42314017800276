




















































import { Component, Prop, Mixins } from "vue-property-decorator";
import { OspitiReturned } from "operatn-api-client";

import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";

import OspiteHandlerMixin from "@/mixins/handlers/OspiteHandlerMixin";

@Component({
  components: {
    OperatnDateInput,
  },
})
export default class OperatnOspiteCauzioneInfo extends Mixins(OspiteHandlerMixin) {
  /* PROPS */

  @Prop({ type: Object, required: true })
  ospite!: OspitiReturned;

  /* DATA */

  private dataRestituzione: Date | null = null;
  private loading = false;

  /* GETTERS */

  get internalValue(): OspitiReturned {
    return this.ospite;
  }
  set internalValue(value: OspitiReturned) {
    this.$emit('update:ospite', value);
  }

  get cauzionePosseduta(): string {
    return this.ospite.possiedeCauzione ? `POSSIEDE CAUZIONE` : `NON POSSIEDE CAUZIONE`;
  }
  get dataUltimaRestituzione(): string {
    return this.ospite.dataRestituzioneCauzione ? this.ospite.dataRestituzioneCauzione.toLocaleDateString() : "MAI RESTITUITA";
  }
  get disabled(): boolean {
    return this.loading || !this.dataRestituzione;
  }

  /* METHODS */

  async restituisciCauzione(): Promise<void> {
    if (!this.disabled) {
      try {
        this.loading = true;
        await this.restituisciCauzioneOspite(this.ospite.id, { dataRestituzione: this.dataRestituzione as Date })
        this.internalValue.possiedeCauzione = false;
        this.internalValue.dataRestituzioneCauzione = this.dataRestituzione;
        this.dataRestituzione = null;
      } finally {
        this.loading = false;
      }
    }
  }
}
